@import '../../style/mixins';

// Note: Glider.min.css is also included and defines some styles, these overwrite some of those

.glider {
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;

	&--noScroll {
		scroll-behavior: auto;
	}

	img {
		display: block;
	}

	&--withDynamicArrows {
		&:has(.glider-prev.disabled):has(.glider-next.disabled) .glider-arrow {
			visibility: hidden !important;
		}
	}
}

.glider-slide {
	scroll-snap-align: center;

	// this prevents tabbing to an invisible slide
	&:not(.active):not(.visible):not(.force-visible) {
		visibility: hidden;
	}
}

.glider-prev,
.glider-next {
	background: rgb(255, 255, 255, 0.8);
	border-radius: 50%;
	height: 30px;
	margin-top: -15px;
	top: 50%;
	width: 30px;
	z-index: 2;

	&::before {
		border-bottom: 2px solid #333;
		border-right: 2px solid #333;
		content: '';
		height: 10px;
		margin: -6px 4px 0;
		opacity: 0.75;
		position: absolute;
		top: 50%;
		width: 10px;
	}

	&:focus,
	&:hover {
		background: rgb(255, 255, 255, 0.7);
	}

	&.disabled {
		visibility: hidden;
	}
}

.glider-prev {
	left: 10px;

	&::before {
		left: 8px;
		transform: rotate(135deg);
	}
}

.glider-next {
	right: 10px;

	&::before {
		right: 8px;
		transform: rotate(-45deg);
	}
}

/* Dots */
.glider-dots {
	display: flex;
	justify-content: center;
	list-style: none;
	margin: 30px auto 0;
	padding: 0;
	text-align: center;
	width: 100%;

	li {
		align-items: center;
		cursor: pointer;
		display: flex;
		display: flex;
		height: 25px;
		justify-content: space-around;
		margin: 0;
		padding: 0;
		position: relative;
		width: 25px;

		button {
			@include retinaBorderWidthHalfPixel;
			background: transparent;
			border: 1px solid #000;
			border-radius: 50%;
			box-sizing: border-box;
			color: transparent;
			cursor: pointer;
			display: block;
			font-size: 0;
			height: 10px;
			line-height: 0;
			padding: 0;
			position: relative;
			width: 10px;

			&:focus {
				&::before {
					opacity: 0;
				}
			}

			&:focus-visible {
				outline: 1px solid var(--fieldHighlightColor);
				outline-offset: 1px;
			}

			&::before {
				background-color: transparent;
				border-radius: 50%;
				content: '';
				height: 100%;
				left: 0;
				opacity: 0;
				position: absolute;
				top: 0;
				transition: all var(--elementAnimationDuration) var(--elementTimingFunction);
				width: 100%;
			}
		}

		&.active button::before {
			background-color: #000;
			opacity: 1;
		}
	}
}

.glider-buttons-inline {
	align-items: center;
	display: none;
	height: 30px;
	justify-content: space-between;
	position: absolute;
	right: 0;
	top: 20px;
	width: 70px;

	.glider-arrow {
		background: #e8e8e8;
		bottom: 0;
		left: 0;
		position: relative;
		right: 0;
		top: 0;

		&::before {
			border-bottom: 2px solid #000;
			border-right: 2px solid #000;
			height: 8px;
			width: 8px;
		}

		&:focus {
			outline: var(--fieldHighlightWidth) solid var(--fieldHighlightColor);
		}

		&.disabled {
			visibility: visible;
		}
	}

	.glider-prev {
		&::before {
			left: 16px;
			margin: -5px 0 0 -4px;
		}
	}

	.glider-next {
		&::before {
			left: 8px;
			margin: -5px -4px 0 0;
		}
	}
}
