#rateUsPage {
	h1 {
		display: inline;
		font-size: 2rem;
		margin: 0 0 2px;
	}

	h2 {
		display: inline-block;
		font-size: 1.45rem;
		font-weight: 500;
	}

	.title {
		float: left;
		width: 280px;
	}

	.help {
		border-left: 1px solid #ccc;
		float: right;
		padding: 10px;
		text-align: center;
		width: 200px;

		p {
			font-size: 1.45rem;
			font-weight: 500;
			margin: 0;
		}
	}

	.zipEmail {
		label {
			font-size: 14px;
		}
	}

	.lpForm {
		.field {

			input[type='text'],
			input[type='email'],
			select {
				display: inline-block;
				margin-right: 5px;
				width: 300px;
			}

			input[type='checkbox'] {
				vertical-align: top;
			}

			.zipCode[type='text'] {
				width: 140px;
			}

			label[for='OptIn'] {
				width: 305px;
			}

			&.fieldCheckbox {
				padding-bottom: 15px;

				label[for=OptIn] {
					width: auto;
				}
			}
		}

		.field label {
			float: none;
		}

		.requiredNote {
			margin: 2px 0;
		}

		.disclaimer {
			font-size: 1.3rem;

			&::before {
				display: none;
			}
		}

		#btnSubmitRating {
			margin-top: 10px;
		}
	}

	.rating {
		height: 42px;
		padding: 5px 0;
		position: relative;

		input {
			appearance: none;
			background: transparent url('/img/_mobile/rateus/rateus-fullStar.png') no-repeat -47px 0;
			background-size: 90px 39px;
			border: none;
			cursor: pointer;
			height: 50px;
			margin-right: 5px;
			width: 42px;

			&::-ms-check {
				display: none;
			}
		}

		&.hasChecked input {
			background-position: 0 0;

			&:checked ~ input {
				background-position: -47px 0;
			}
		}
	}

	textarea {
		box-sizing: border-box;
		width: 100%;
	}

	#privacyPolicy {
		margin: 5px auto;
		width: 560px;
	}

	.overallRatingValidation {
		margin-left: 20px;

		span.error:not(:empty) {
			display: inline-block;
		}

		.ratingFocusError {
			outline-color: #c00;
		}
	}

	.disclaimer {
		line-height: 20px;
		margin: 25px 0 0;
	}
}

#rateUsPrivacyPolicy {
	h2 {
		display: inline;
		font-size: 2rem;
		font-weight: 500;
		margin: 0 10px 2px 0;
	}

	h3 {
		font-size: 1.45rem;
		font-weight: 500;
	}
}

#lpModal.rateUsLpModel {
	#lpModalContent {
		overflow-x: hidden !important;
		overflow-y: auto !important;
		padding: 5px;
	}
}

#reviewUsConfirmation {
	padding: 30px;
}