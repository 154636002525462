/*
	These values must correspond to the JS
	media queries in _client/utilities/browser/media.js
*/
$desktopMinDeviceWidth: 768px;
$desktopMinDeviceHeight: 720px; // Support Desktop media query for small screens like a netbook at 1024x720

@mixin media($res) {
	@if mobile == $res {
		@media only screen and (max-device-width: #{$desktopMinDeviceWidth - 1})
			,  only screen and (max-device-height: #{$desktopMinDeviceHeight - 1}) and (orientation: landscape) {
			@content;
		}
	}
	@if desktop == $res {
		@media only screen and (min-device-width: $desktopMinDeviceWidth) and (min-device-height: $desktopMinDeviceHeight) {
			@content;
		}
	}
	@if desktop1280 == $res {
		@media only screen and (min-device-width: $desktopMinDeviceWidth) and (min-device-height: $desktopMinDeviceHeight) and (min-width: 1280px) {
			@content;
		}
	}
	@if desktop1440 == $res {
		@media only screen and (min-device-width: $desktopMinDeviceWidth) and (min-device-height: $desktopMinDeviceHeight) and (min-width: 1440px) {
			@content;
		}
	}
}

@mixin retinaBorderWidthHalfPixel {
	@media (min-device-pixel-ratio: 2) {
		& {
			border-width: 0.5px;
		}
	}
	@media (min-device-pixel-ratio: 3) {
		& {
			border-width: 1px;
		}
	}
}

@mixin caret($size: 8px, $thickness: 1px, $color: #000, $disabledColor: #ccc) {
	border-bottom: $thickness solid $color;
	border-right: $thickness solid $color;
	height: $size;
	width: $size;

	&.disabled {
		border-color: $disabledColor;
		cursor: default;
	}
}
